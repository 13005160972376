<template>
    <b-row v-if="loading">
        <b-col>
            <div class="text-center text-primary my-2">
                <b-spinner class="align-middle"></b-spinner>
            </div>
            <div class="text-center text-primary my-2">
                <strong>Cargando Riesgo Sociedades por Actividad </strong>
            </div>
        </b-col>
    </b-row>
    <div v-else>
        <div class="box-menu">
            <b-icon icon="arrow-clockwise" @click="retrieveStadistics" v-b-tooltip.hover title="Actualizar" scale="1" />
        </div>
        <h5 class="card-title">Riesgo Sociedades por Actividad </h5>
        <BarChartActivitiesWrapper :data="data" />
    </div>
</template>

<script>
import { RepositoryFactory } from "@/services/api";

import BarChartActivitiesWrapper from "@/components/charts/BarChartActivitiesWrapper.vue";

function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

export default {
    name: "risk-hight-countries-chart",
    components: {
        BarChartActivitiesWrapper,
    },
    data() {
        return {
            loading: true,
            data: null
        };
    },
    mounted() {
        this.retrieveStadistics();
    },
    methods: {
        async retrieveStadistics() {
            this.loading = true;
            let response;
            try {
                response = await RepositoryFactory.stadistics.getRiskHightActivitiesLoad();
                console.log(response)
                this.data = response.data
            } catch {
                window.console.log("Error", response);
            }
            await sleep(1500);
            this.loading = false;
        },
    },
};
</script>
