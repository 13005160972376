<template>
    <b-row v-if="loading">
        <b-col>
            <div class="text-center text-primary my-2">
                <b-spinner class="align-middle"></b-spinner>
            </div>
            <div class="text-center text-primary my-2">
                <strong>Cargando Solicitudes de Autoridades Competentes</strong>
            </div>
        </b-col>
    </b-row>
    <div v-else>
        <div class="box-menu">
            <b-icon icon="arrow-clockwise" @click="retrieveStadistics" v-b-tooltip.hover title="Actualizar" scale="1" />
        </div>
        <h5 class="card-title">Solicitudes de Autoridades Competentes</h5>
        <BarTimeChartWrapper :data="data" />
        <div class="legend-conatiner">
            <div class="label">
                <span class="law-dot"></span>
                <p>Solicitudes</p>
            </div>
            <div class="label">
                <span class="natural-dot"></span>
                <p>Folios</p>
            </div>
        </div>
    </div>
</template>

<script>
import { RepositoryFactory } from "@/services/api";

import BarTimeChartWrapper from "@/components/charts/BarTimeChartWrapper.vue";

function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

export default {
    name: "superintendence-request-time-chart",
    components: {
        BarTimeChartWrapper,
    },
    data() {
        return {
            loading: true,
            data: null
        };
    },
    mounted() {
        this.retrieveStadistics();
    },
    methods: {
        async retrieveStadistics() {
            this.loading = true;
            let response;
            try {
                response = await RepositoryFactory.stadistics.getSuperintendenceRequestTimeLoad();
                this.data = response.data
            } catch {
                window.console.log("Error", response);
            }
            await sleep(1500);
            this.loading = false;
        },
    },
};
</script>
<style>
.legend-conatiner {
    display: flex;
    gap: 20px;
}

.law-dot {
    height: 10px;
    width: 10px;
    background-color: #C8BFE7;
    border-radius: 50%;
    display: inline-block;
}

.natural-dot {
    height: 10px;
    width: 10px;
    background-color: #456B8F;
    border-radius: 50%;
    display: inline-block;
}

p {
    font-size: 10px;
}

.label {
    display: flex;
    gap: 5px;
}
</style>