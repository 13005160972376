<template>
    <div class="chart-container">
        <BarChartCustom :data="chartData" :scales="defaultScales" />
    </div>
</template>

<script>
import BarChartCustom from "./BarChartCustom.vue";

export default {
    name: "chart-time-wrapper",
    components: {
        BarChartCustom,
    },
    props: {
        data: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            defaultScales: {
                xAxes: [{
                    ticks: {
                        minRotation: 90,
                        padding: -32
                    },
                    gridLines: {
                        display: false, // hide separate line each bar
                        drawOnChartArea: false,
                        tickMarkLength: 40
                    }
                },
                {
                    display: false, // hide labels
                    offset: false,
                    labels: this.data.map(n => n.months.map(q => q.month)).flat(),
                    gridLines: {
                        display: false
                    },
                },
                {
                    offset: true,
                    labels: this.data.map(n => n.year).flat(),
                    gridLines: {
                        display: true
                    },
                    scaleLabel: {
                        display: false,
                        labelString: 'Date'
                    }
                }],
                yAxes: [{
                    scaleLabel: {
                        display: false,
                        labelString: 'value'
                    },
                    ticks: {
                        beginAtZero: true,
                        display: true
                    },
                    gridLines: {
                        display: false
                    },
                }]
            },
            chartData: {
                labels: [],
                datasets: [
                    {
                        data: [],
                        fill: true,
                        borderWidth: 4,
                        backgroundColor: "#C8BFE7",
                    },
                    {
                        data: [],
                        fill: true,
                        borderWidth: 4,
                        backgroundColor: "#456B8F",
                    }
                ],
            },
            chartOption: {
                maintainAspectRatio: false,
                responsive: true,
                title: {
                    display: false,
                    text: ""
                },
                legend: {
                    display: false
                },

            }
        };
    },
    created() {
        const months = this.data.map(n => n.months.map(q => q.month)).flat();
        const requests = this.data.map(n => n.months.map(q => q.requests)).flat();
        const folios = this.data.map(n => n.months.map(q => q.folios)).flat();
        this.chartData.labels = months;
        this.chartData.datasets[0].data = requests;
        this.chartData.datasets[1].data = folios;
    },
};
</script>

<style scoped>
.chart-container {
    margin: auto;
}
</style>