<template>
    <div>
        <h6 class="text-center page-title text-uppercase">
            Beneficiarios finales
        </h6>
        <ChartWrapper :labels="labels" :values="values" />
    </div>
</template>

<script>
import ChartWrapper from "@/components/charts/ChartWrapper.vue";

function compare(a, b) {
    if (a.finalBeneficiaries >= b.finalBeneficiaries) {
        return -1;
    }
    return 1;
}

export default {
    name: "final-beneficiaries-by-law-firm-chart",
    components: {
        ChartWrapper,
    },
    props: {
        data: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            loading: true,
            labels: [],
            values: [],
        };
    },
    created() {
        let data = [...this.data];
        data.sort(compare);
        data = this.groupData(data);
        data.sort(compare);
        this.labels = data.map(({ name }) => name);
        this.values = data.map(({ finalBeneficiaries }) => finalBeneficiaries);
    },
    methods: {
        groupData(data) {
            let result = data.splice(0, 4);

            let others = {
                id: "other",
                name: "Otros",
                finalBeneficiaries: data.reduce(
                    (a, b) => a + b["finalBeneficiaries"],
                    0
                ),
            };

            result.push(others);
            return result;
        },
    },
};
</script>