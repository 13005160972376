<template>
    <b-card no-body class="text-center p-2">
        <h5 class="card-title">
            {{ label }}
        </h5>
        <div class="counter-value page-title">
            <span v-b-tooltip.hover title="Total" class="cs-pointer">
                {{ value }}
            </span>
            <span v-b-tooltip.hover title="Registrados" class="cs-pointer" v-if="subValue">
                / {{ subValue }}
            </span>
        </div>
    </b-card>
</template>

<script>
export default {
    name: "counter",
    props: {
        label: {
            type: String,
            default: "",
        },
        value: {
            type: Number,
            default: 0,
        },
        subValue: {
            type: Number,
            default: null,
            require: false
        },
    },
};
</script>

<style scoped>
.counter-value {
    font-size: 1.5rem;
    font-weight: bold;
}
</style>