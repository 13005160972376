<template>
    <div>
        <div class="mb-3">
            <b-row align-h="center">
                <b-col md="6">
                    <b-card no-body class="text-center p-2">
                        <b-row>
                            <b-col>
                                <ResidentAgentPJSummaryLoadChart />
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>
                <b-col md="6">
                    <b-card no-body class="text-center p-2">
                        <b-row>
                            <b-col>
                                <ResidentAgentBFSummaryLoadChart />
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>
            </b-row>
        </div>
        <div class="">
            <b-row align-h="center">
                <!-- <b-col md="6">
                    <b-card no-body class="text-center p-2">
                        <b-row>
                            <b-col>
                                <ResidentAgentTopologyLoadChart />
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col> -->
                <b-col md="12">
                    <b-card no-body class="text-center p-2">
                        <b-row>
                            <b-col>
                                <ResidentAgentsMonthlyLoadChart />
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>

import ResidentAgentPJSummaryLoadChart from "@/components/dashboard-resident-agent/charts/ResidentAgentPJSummaryLoadChart.vue";
import ResidentAgentBFSummaryLoadChart from "@/components/dashboard-resident-agent/charts/ResidentAgentBFSummaryLoadChart.vue";
// import ResidentAgentTopologyLoadChart from "@/components/dashboard-resident-agent/charts/ResidentAgentTopologyLoadChart.vue";
import ResidentAgentsMonthlyLoadChart from "@/components/dashboard-resident-agent/charts/ResidentAgentsMonthlyLoadChart.vue";

export default {
    name: "recent-resident-agent-charts",
    components: {
        ResidentAgentPJSummaryLoadChart,
        ResidentAgentBFSummaryLoadChart,
        // ResidentAgentTopologyLoadChart,
        ResidentAgentsMonthlyLoadChart
    }
};
</script>