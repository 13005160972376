<template>
    <div class="mt-5 px-xl-5">
        <h6 class="page-title pb-1 mt-3">RESUMEN</h6>
        <b-row class="mt-2">
            <b-col xl="10" md="10">
                <!-- <ResidentAgentsCharts /> -->
                <RecentResidentAgentsCharts />
            </b-col>
            <b-col xl="2" md="2">
                <b-card no-header no-body class="rounded-0 shadow-sm cs-pointer mini-card mb-1"
                    @click="redirectToCreateLegalEntity" v-if="allowFinalBeneficiary">
                    <b-row class="my-2">
                        <b-col>
                            <b-icon icon="plus" class="mini-card-plus" />
                            <h6 class="mini-card-title">
                                Registrar persona jurídica
                            </h6>
                            <div class="icon-container">
                                <b-icon-person-plus font-scale="2" />
                            </div>
                        </b-col>
                    </b-row>
                </b-card>
                <b-card no-header no-body class="rounded-0 mt-1 shadow-sm cs-pointer mini-card mb-1"
                    @click="redirectToResidentAgents" v-if="allowResidentAgentsRoles">
                    <b-row class="my-2">
                        <b-col>
                            <b-icon icon="plus" class="mini-card-plus" />
                            <h6 class="mini-card-title">Ver roles</h6>
                            <div class="icon-container">
                                <b-icon-person-plus-fill font-scale="2" />
                            </div>
                        </b-col>
                    </b-row>
                </b-card>
                <b-card no-header no-body class="rounded-0 mt-1 shadow-sm cs-pointer mini-card mb-1"
                    @click="redirectToProfile">
                    <b-row class="my-2">
                        <b-col>
                            <h6 class="mini-card-title">Perfil</h6>
                            <div class="icon-container">
                                <b-icon-person-circle font-scale="2" />
                            </div>
                        </b-col>
                    </b-row>
                </b-card>
                <b-card no-header no-body class="rounded-0 mt-1 shadow-sm cs-pointer mini-card mb-1"
                    @click="redirectToAlerts">
                    <b-row class="my-2">
                        <b-col>
                            <h6 class="mini-card-title">Alertas</h6>
                            <div class="icon-container">
                                <b-icon-bell font-scale="2" />
                            </div>
                        </b-col>
                    </b-row>
                </b-card>
                <b-card no-header no-body class="rounded-0 card-input">
                    <h6 class="dash-card-title">Empresa</h6>
                    <div class="dash-card-input d-flex">
                        <b-form-input v-model="company" placeholder="Buscar por folio" class="rounded-0" size="sm"
                            @keydown.enter="searchLegalEntity" />
                        <b-button class="ms-3 rounded-0" size="sm" @click="searchLegalEntity">
                            <b-icon-search />
                        </b-button>
                    </div>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
// import ResidentAgentsCharts from "./ResidentAgentsCharts.vue";
import RecentResidentAgentsCharts from "./RecentResidentAgentsCharts.vue";
import { mapGetters } from "vuex";

export default {
    name: "dashboard",
    components: {
        // ResidentAgentsCharts,
        RecentResidentAgentsCharts
    },
    data() {
        return {
            company: "",
        };
    },
    computed: {
        ...mapGetters("application", [
            "allowResidentAgentAdminFinalBeneficiaries",
            "allowResidentAgentCreateRoles"
        ]),
        allowFinalBeneficiary() {
            return this.allowResidentAgentAdminFinalBeneficiaries;
        },
        allowResidentAgentsRoles() {
            return this.allowResidentAgentCreateRoles;
        },
    },
    methods: {
        redirectToCreateLegalEntity() {
            this.$router.push({
                name: "new-legal-entity",
            });
        },
        redirectToResidentAgents() {
            this.$router.push({
                name: "resident-agent-list",
            });
        },
        redirectToProfile() {
            this.$router.push({
                name: "resident-agent-profile",
            });
        },
        redirectToAlerts() {
            this.$router.push({
                name: "alerts-resident-agent",
            });
        },
        searchLegalEntity() {
            if (this.company !== "") {
                this.$router.push({
                    name: "legal-entities",
                    query: { search: this.company },
                });
            } else {
                this.$router.push({
                    name: "legal-entities",
                });
            }
        },
    },
};
</script>

<style>
.dash-card {
    border-bottom: 3px solid #597fa4;
    box-shadow: 4px 1px 5px #88888873;
    border-right: unset;
    border-top: unset;
    border-left: unset;
}

.dash-card-title {
    color: #406892;
    background-color: rgb(212 221 229);
    padding: 0.5rem 0 0.5rem 1rem;
    border-bottom: 1px solid #597fa4;
    margin-bottom: 0;
}

/*table*/
.dash-table {
    margin-bottom: 0;
}

.dash-table tbody tr {
    border: 1px solid #03a9f447;
    color: #746d6dfc;
    font-weight: 500;
    font-size: 14px;
}

.dash-table tbody>tr>td {
    padding-left: 1rem;
}

.dash-table thead {
    display: none;
}

.mini-card {
    background-color: #597fa4;
    border-bottom: 3px solid #ffffff;
}

.mini-card-title {
    color: #ffffff;
    border-bottom: 1.5px solid #ffffff;
    padding: 0 0 0.35rem 0.5rem;
    font-weight: 400;
}

.mini-card-plus {
    color: #ffffff;
    font-size: 26px;
    float: right;
    background-color: rgb(68 96 123);
    margin-top: -8px;
}

.icon-container {
    text-align: center;
    color: #ffffff;
}

.dash-tab .nav-link.active {
    background-color: rgb(212 221 229);
    border-radius: 0;
    color: #406892;
    font-weight: 500;
    border-bottom-color: rgb(212 221 229);
    margin-bottom: 0;
}

.dash-tab .nav-link {
    padding: 0.3rem;
    color: #746d6dfc;
    font-weight: 500;
}

.dash-tab .nav-link:hover {
    border-radius: 0;
}

.dash-nav-tab {
    border-bottom: 0.2px solid #597fa4;
}

.card-input {
    background-color: rgb(212 221 229);
    border-bottom: 3px solid #597fa4;
    box-shadow: 4px 1px 5px #88888873;
}

.card-input .dash-card-title {
    border-bottom: 2.3px solid #597fa4;
}

.dash-card-input {
    margin: 25px;
}

.dash-card-input button {
    background-color: #597fa4;
}

.report-card {
    background-color: #597fa4;
    color: #ffffff;
    border-bottom: 3px solid #cfcfcf !important;
}

.apexcharts-menu-icon {
    display: none !important;
}

@media (max-width: 768px) {
    .dash-table>tbody>tr>[data-label]>div {
        width: unset !important;
    }
}

@media (min-width: 768px) and (max-width: 1000px) {
    .mini-card-title {
        font-size: 14px;
    }

    .dash-card-input {
        margin: 15px;
    }
}
</style>