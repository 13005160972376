<template>
    <b-row v-if="loading">
        <b-col>
            <div class="text-center text-primary my-2">
                <b-spinner class="align-middle"></b-spinner>
            </div>
            <div class="text-center text-primary my-2">
                <strong>Cargando Solicitudes </strong>
            </div>
        </b-col>
    </b-row>
    <div v-else>
        <div class="box-menu">
            <b-icon icon="arrow-clockwise" @click="retrieveStadistics" v-b-tooltip.hover title="Actualizar" scale="1" />
            <b-icon-calendar2-event v-show="!details" @click="showDetails" scale="1" />
            <b-icon-graph-up v-show="details" @click="showDetails" scale="1" />
        </div>

        <OfficialChart v-show="!details" title="Solicitudes" :labels="['Procesadas', 'Sin Procesar']" :data="dataChart"
            :showPercent=true />

        <SuperintendenceProcedureDetailsChart v-show="details" />
    </div>
</template>

<script>
import { RepositoryFactory } from "@/services/api";

import OfficialChart from "@/components/dashboard-official/charts/OfficialChart.vue";
import SuperintendenceProcedureDetailsChart from "@/components/dashboard-superintendencia/charts/SuperintendenceProcedureDetailsChart.vue";

export default {
    name: "superintendence-summary-procedures-chart",
    components: {
        OfficialChart,
        SuperintendenceProcedureDetailsChart
    },
    data() {
        return {
            loading: true,
            dataChart: {
                percent: [],
                count: 0,
                total: 0,
            },
            details: false
        };
    },
    mounted() {
        this.retrieveStadistics();
    },
    methods: {
        async retrieveStadistics() {
            this.loading = true;
            let response;
            try {
                response = await RepositoryFactory.stadistics.getSuperintendenceProceduresLoad();
                this.dataChart.percent = response.data.percent;
                this.dataChart.count = response.data.count;
                this.dataChart.total = response.data.total;
            } catch {
                window.console.log("Error", response);
            }
            this.loading = false;
        },
        showDetails() {
            this.details = !this.details
        }

    },
};
</script>
<style>
.details {
    position: absolute;
    right: 0px;
    cursor: pointer;
}
</style>