<template>
    <b-row v-if="loading">
        <b-col>
            <div class="text-center text-primary my-2">
                <b-spinner class="align-middle"></b-spinner>
            </div>
            <div class="text-center text-primary my-2">
                <strong>Cargando AR Registrados</strong>
            </div>
        </b-col>
    </b-row>
    <div v-else>
        <div class="box-menu">
            <b-icon icon="arrow-clockwise" @click="retrieveStadistics(true)" v-b-tooltip.hover title="Actualizar"
                scale="1" />
        </div>
        <ResidentAgentChart title="AR Registrados" :labels="labels" :data="dataChart" :showPercent=true />
    </div>
</template>

<script>
import { RepositoryFactory } from "@/services/api";
import { mapActions, mapGetters } from "vuex";
import ResidentAgentChart from "@/components/dashboard-superintendencia/charts/ResidentAgentChart.vue";

export default {
    name: "resident-agent-superintendence-registered-charts",
    components: {
        ResidentAgentChart,
    },
    data() {
        return {
            loading: true,
            dataChart: {
                percent: [],
                count: 0,
                total: 0,
                naturalPersonPercent: 0,
                legalPersonPercent: 0,
                legalPersonCount: 0,
                naturalPersonCount: 0
            },
            dataSegmentation: [],
            label: 'Agentes Residentes',
            labels: ['Agentes Residentes', 'Agentes Residentes']
        };
    },
    mounted() {
        this.retrieveStadistics(false);
    },
    methods: {
        ...mapActions("StadisticStore", ["setRegisteredChart"]),
        ...mapGetters("StadisticStore", ["getRegisteredChart"]),
        async retrieveStadistics(isRefresh) {
            this.loading = true;
            const store = isRefresh ? null : this.getRegisteredChart()
            if (store === null || store === undefined) {
                try {
                    let response = await RepositoryFactory.stadistics.getSuperintendenceResidentAgentRegistered();
                    this.setDataChart(response.data)
                    this.setRegisteredChart(response.data)

                } catch {
                    window.console.log("Load Error: Resident Agent Register Chart");
                }
            }
            else {
                this.setDataChart(store)
            }
            this.loading = false;
        },
        setDataChart(data) {
            this.dataChart.percent = data.percent;
            this.dataChart.count = data.count;
            this.dataChart.total = data.total;
            this.dataChart.naturalPersonPercent = data.naturalPersonPercent;
            this.dataChart.legalPersonPercent = data.legalPersonPercent;
            this.dataChart.legalPersonCount = data.legalPersonCount;
            this.dataChart.naturalPersonCount = data.naturalPersonCount;
        }
    },
};
</script>
<style>
.box-menu {
    display: flex;
    gap: 5px;
    flex-direction: row-reverse;
    cursor: pointer;
    z-index: -1;
}
</style>