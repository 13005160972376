<template>
    <b-row v-if="loading">
        <b-col>
            <div class="text-center text-primary my-2">
                <b-spinner class="align-middle"></b-spinner>
            </div>
            <div class="text-center text-primary my-2">
                <strong>Cargando AR en Producción</strong>
            </div>
        </b-col>
    </b-row>
    <div v-else>
        <div class="box-menu">
            <b-icon icon="arrow-clockwise" @click="retrieveStadistics(true)" v-b-tooltip.hover title="Actualizar"
                scale="1" />
        </div>
        <BarChartCustomWrapper :typeChart="'Segmentation'" :label="label" title="Segmentación AR en Producción"
            :data="dataSegmentation" />
    </div>
</template>

<script>
import { RepositoryFactory } from "@/services/api";
import { mapActions, mapGetters } from "vuex";
import BarChartCustomWrapper from "@/components/charts/BarChartCustomWrapper.vue";

export default {
    name: "resident-agent-superintendence-inProd-charts",
    components: {
        BarChartCustomWrapper
    },
    data() {
        return {
            loading: true,
            dataSegmentation: [],
            label: 'Agentes Residentes',
            labels: ['Agentes Residentes', 'Agentes Residentes']
        };
    },
    mounted() {
        this.retrieveStadistics(false);
    },
    methods: {
        ...mapActions("StadisticStore", ["setProductionChart"]),
        ...mapGetters("StadisticStore", ["getProductionChart"]),
        async retrieveStadistics(isRefresh) {
            const store = isRefresh ? null : this.getProductionChart()
            if (store === null || store === undefined) {
                this.loading = true;
                let response = await RepositoryFactory.stadistics.getSuperintendenceResidentAgentInProduction();
                this.setDataChart(response.data)
                this.setProductionChart(response.data)
            }
            else {
                this.setDataChart(store)
            }
            this.loading = false;
        },
        setDataChart(data) {
            this.dataSegmentation = [
                {
                    key: 'Firmas de Abogados', value: data.legalPersonCount,
                },
                {
                    key: 'Persona Natural', value: data.naturalPersonCount,
                }
            ]
        },
    },
};
</script>
<style>
.box-menu {
    display: flex;
    gap: 5px;
    flex-direction: row-reverse;
    cursor: pointer;
    z-index: -1;
}
</style>