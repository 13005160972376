<template>
    <div class="chart-container">
        <HalfDoughnutChart :data="chartData" />
    </div>
</template>

<script>
import config from "@/config/charts-colors.js";
const colors = config.chartsColors;

import HalfDoughnutChart from "./HalfDoughnutChart.vue";

export default {
    name: "half-doughnut-chart-wrapper",
    components: {
        HalfDoughnutChart,
    },
    props: {
        labels: {
            type: Array,
            required: true,
        },
        values: {
            type: Array,
            required: true,
        },
        counter: {
            type: Number,
            required: true
        },
        total: {
            type: Number,
            required: true
        },
        showPercent: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            chartData: {
                labels: [],
                datasets: [
                    {
                        data: [],
                        fill: true,
                        borderWidth: 0,
                        backgroundColor: colors.backgroundHalfDoughnutColors,
                        borderColor: colors.borderColors,
                        cutout: '70%',
                    },
                ],
                chartCounter: 0,
                chartTotal: 0,
                chartShowPercent: true
            }
        };
    },
    created() {
        this.chartData.labels = this.labels;
        this.chartData.datasets[0].data = this.values;
        this.chartData.chartCounter = this.counter;
        this.chartData.chartTotal = this.total;
        this.chartData.chartShowPercent = this.showPercent;
    },
};
</script>

<style scoped>
.chart-container {
    max-width: 250px;
    margin: auto;
}
</style>