<template>
    <div class="chart-container">
        <BarChartCustom :data="chartData" />
    </div>
</template>

<script>
import config from "@/config/charts-colors.js";
const colors = config.chartsColors;

import BarChartCustom from "./BarChartCustom.vue";

export default {
    name: "chart-yearly-wrapper",
    components: {
        BarChartCustom,
    },
    props: {
        data: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {

            chartData: {
                labels: [],
                datasets: [
                    {
                        data: [],
                        fill: true,
                        borderWidth: 4,
                        backgroundColor: colors.backgroundBarColors,
                    }
                ],
            },
            chartOption: {
                maintainAspectRatio: false,
                responsive: true,

                title: {
                    display: false,
                    text: ""
                },
                legend: {
                    display: false
                },
                scales: {
                    xAxes: [{

                        gridLines: {
                            display: false, // hide separate line each bar
                            drawOnChartArea: false,
                            tickMarkLength: 40
                        }
                    },
                    {
                        display: false, // hide labels
                        offset: false,
                        // labels: this.data.map(n => Object.keys(n.months)).flat(),
                        gridLines: {
                            display: false
                        },
                    },
                    ],
                    yAxes: [{
                        scaleLabel: {
                            display: false,
                            labelString: 'value'
                        },
                        ticks: {
                            beginAtZero: true,
                            display: true

                        },
                        gridLines: {
                            display: false
                        },
                    }]
                },
                plugins: {
                    datalabels: {
                        anchor: 'end',
                        align: 'top',
                        formatter: () => {
                            return ''; // muestra el valor de la barra
                        }
                    }
                }
            }
        };
    },
    created() {
        const years = this.data.map(n => n.year);

        const items = this.data.map(item =>
            item.total
        );

        const values = items.map((total, index) => {
            return [index === 0 ? index : items[index - 1], total]
        });

        const total = items.reduce((a, b) => a + b, 0);

        years.push('Total');
        values.push(total);

        this.chartData.labels = years;
        this.chartData.datasets[0].data = values;
    },
};
</script>

<style scoped>
.chart-container {
    margin: auto;
}
</style>