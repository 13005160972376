<template>
    <div>
        <canvas ref="myBarChart" height="230"></canvas>
    </div>
</template>

<script>
import Chart from 'chart.js';

export default {
    props: {
        data: {
            type: Object,
            required: true,
        },
        scales: {
            type: Object,
            required: false,
            default: null
        }
    },
    data() {
        return {
            chart: null,
            defaultScales: {
                xAxes: [
                    {
                        gridLines: {
                            display: false, // hide separate line each bar
                            drawOnChartArea: false,
                            tickMarkLength: 40
                        }
                    },
                    {
                        display: false, // hide labels
                        offset: false,
                        gridLines: {
                            display: false
                        },
                    },
                ],
                yAxes: [{
                    scaleLabel: {
                        display: false,
                        labelString: 'value'
                    },
                    ticks: {
                        display: false,
                        beginAtZero: true,
                        callback: function (value) {
                            return value; // Mostrar el valor de la barra
                        }
                    },
                    gridLines: {
                        display: false
                    },
                }]
            }
        };
    },
    mounted() {
        this.defaultScales = this.scales === null ? this.defaultScales : this.scales
        this.renderChart();
    },
    methods: {
        renderChart() {
            var ctx = this.$refs.myBarChart.getContext('2d');

            var config = {
                type: 'bar',

                data: {
                    labels: this.data.labels,
                    datasets: this.data.datasets
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    layout: {
                        padding: {
                            top: 30
                        }
                    },
                    legend: {
                        display: false,
                    },
                    title: {
                        display: false,
                        text: ''
                    },
                    scales: this.defaultScales,
                    hover: {
                        "animationDuration": 0
                    },
                    animation: {
                        "duration": 1,
                        "onComplete": function () {
                            var chartInstance = this.chart
                            ctx = chartInstance.ctx;
                            ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontSize, Chart.defaults.global.defaultFontStyle, Chart.defaults.global.defaultFontFamily);
                            ctx.fillStyle = this.data.backgroundColor;
                            ctx.textAlign = 'left';
                            ctx.textBaseline = 'top';

                            this.data.datasets.forEach(function (dataset, i) {
                                var meta = chartInstance.controller.getDatasetMeta(i);
                                meta.data.forEach(function (bar, index) {
                                    const data = dataset.data[index];
                                    ctx.fillText(data, bar._model.x - 5, bar._model.y - 15);
                                });
                            });
                        }
                    },
                }
            };

            this.chart = new Chart(ctx, config);
        }
    },
    destroyed() {
        if (this.chart) {
            this.chart.destroy();
        }
    }
};
</script>