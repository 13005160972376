<template>
    <div class="chart-container">
        <h5 class="card-title">{{ title }}</h5>
        <BarChartCustom :data="chartData" />
    </div>
</template>

<script>
import config from "@/config/charts-colors.js";
const colors = config.chartsColors;

import BarChartCustom from "./BarChartCustom.vue";


export default {
    name: "bar-custom-chart-wrapper",
    components: {
        BarChartCustom,
    },
    props: {
        title: {
            type: String,
            required: false
        },
        data: {
            type: Object,
            required: true,
        },
        label: {
            type: String,
            required: false,
        },
        typeChart: {
            type: String,
            required: false
        }
    },
    data() {
        return {
            chartData: {
                labels: [],
                datasets: [
                    {
                        data: [],
                        fill: true,
                        borderWidth: 0,
                        borderColor: colors.borderColors,
                        backgroundColor: this.typeChart === 'Segmentation' ? colors.backgroundHalfDoughnutColors : colors.backgroundBarColors,
                    },
                ],
                label: 'Agentes Residentes',
            }
        };
    },
    created() {
        // const labels = this.data.map(n => n.key);
        const data = this.data.map(item => item.value);
        const total = data.reduce((sum, val) => sum + val, 0);

        const collection = this.data.map(item => `${item.key} (${Math.trunc(item.value * 100 / total)}%)`);

        this.chartData.labels = collection;
        this.chartData.datasets[0].data = data;
    },
};
</script>

<style scoped>
.chart-container {
    max-width: 250px;
    margin: auto;
}

.legend-conatiner {
    display: flex;
    gap: 20px;
}

.law-dot {
    height: 10px;
    width: 10px;
    background-color: #A05195;
    border-radius: 50%;
    display: inline-block;
}

.natural-dot {
    height: 10px;
    width: 10px;
    background-color: #303680;
    border-radius: 50%;
    display: inline-block;
}

p {
    font-size: 10px;
}

.label {
    display: flex;
    gap: 5px;
}
</style>