<template>
    <b-row v-if="loading">
        <b-col>
            <div class="text-center text-primary my-2">
                <b-spinner class="align-middle"></b-spinner>
            </div>
            <div class="text-center text-primary my-2">
                <strong>Cargando Carga Anual</strong>
            </div>
        </b-col>
    </b-row>
    <div v-else>
        <div class="box-menu">
            <b-icon icon="arrow-clockwise" @click="retrieveStadistics(true)" v-b-tooltip.hover title="Actualizar"
                scale="1" />
        </div>

        <h5 class="card-title">Carga Anual</h5>
        <BarYearlyChartWrapper :data="data" />
    </div>
</template>

<script>
import { RepositoryFactory } from "@/services/api";
import { mapActions, mapGetters } from "vuex";
import BarYearlyChartWrapper from "@/components/charts/BarYearlyChartWrapper.vue";

export default {
    name: "resident-agent-superintendence-yearly-load-chart",
    components: {
        BarYearlyChartWrapper,
    },
    data() {
        return {
            loading: true,
            data: null
        };
    },
    mounted() {
        this.retrieveStadistics(false);
    },
    methods: {
        ...mapActions("StadisticStore", ["setLoadYearlySummaryChart"]),
        ...mapGetters("StadisticStore", ["getLoadYearlySummaryChart"]),

        async retrieveStadistics(isRefresh) {
            this.loading = true;
            const store = isRefresh ? null : this.getLoadYearlySummaryChart()
            if (store === null || store === undefined) {
                let response;
                try {
                    response = await RepositoryFactory.stadistics.getSuperintendenceLoad();
                    this.data = response.data
                    this.setLoadYearlySummaryChart(response.data);
                } catch {
                    window.console.log("Error", response);
                }
            } else {
                this.data = store;
            }
            this.loading = false;
        },
    },
};
</script>
<style>
.box-menu {
    display: flex;
    gap: 5px;
    flex-direction: row-reverse;
    cursor: pointer;
    z-index: -1;
}
</style>