<template>
    <div v-if="isResidentAgent">
        <ResidentAgentDashboard />
    </div>
    <div v-else-if="isSuperintendence">
        <SuperintendenceDashboard />
    </div>
    <div v-else-if="isOfficial">

        <OfficialDashboard />
    </div>
</template>

<script>
import { mapGetters } from "vuex";

import ResidentAgentDashboard from "./dashboard-resident-agent/Dashboard.vue";
import SuperintendenceDashboard from "./dashboard-superintendencia/Dashboard.vue";
import OfficialDashboard from "./dashboard-official/Dashboard.vue";

export default {
    name: "dashboard",
    components: {
        ResidentAgentDashboard,
        SuperintendenceDashboard,
        OfficialDashboard
    },
    computed: {
        ...mapGetters("application", [
            "isResidentAgent",
            "isSuperintendence",
            "isOfficial",
        ]),
    },
};
</script>