<template>
    <div class="md-12">
        <div class="mb-3">
            <b-row align-h="center">
                <b-col md="6">
                    <b-card no-body class="text-center p-2">
                        <b-row>
                            <b-col>
                                <OfficialSummaryProceduresChart />
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>
                <b-col md="6">
                    <b-card no-body class="text-center p-2">
                        <b-row>
                            <b-col>
                                <OfficialPJRequestedProceduresChart />
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>
            </b-row>
        </div>
        <div class="">
            <b-row align-h="center">
                <b-col md="4">
                    <b-card no-body class="text-center p-2">
                        <b-row>
                            <b-col>
                                <OfficialPendingProceduresChart />
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>
                <b-col md="4">
                    <b-card no-body class="text-center p-2">
                        <b-row>
                            <b-col>
                                <OfficialCompletedProceduresChart />
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>
                <b-col md="4">
                    <b-card no-body class="text-center p-2">
                        <b-row>
                            <b-col>
                                <OfficialCancelledProceduresChart />
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>

import OfficialSummaryProceduresChart from "@/components/dashboard-official/charts/OfficialSummaryProceduresChart.vue";
import OfficialPJRequestedProceduresChart from "@/components/dashboard-official/charts/OfficialPJRequestedProceduresChart.vue";
import OfficialPendingProceduresChart from "@/components/dashboard-official/charts/OfficialPendingProceduresChart.vue";
import OfficialCompletedProceduresChart from "@/components/dashboard-official/charts/OfficialCompletedProceduresChart.vue";
import OfficialCancelledProceduresChart from "@/components/dashboard-official/charts/OfficialCancelledProceduresChart.vue";

export default {
    name: "recent-official-charts",
    components: {
        OfficialSummaryProceduresChart,
        OfficialPJRequestedProceduresChart,
        OfficialPendingProceduresChart,
        OfficialCompletedProceduresChart,
        OfficialCancelledProceduresChart
    }
};
</script>