<script>
import { Pie } from "vue-chartjs";

export default {
    extends: Pie,
    props: ["data"],
    data() {
        return {
            chartOptions: {
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                    display: true,
                    position: "bottom",
                },
            },
        };
    },
    mounted() {
        this.renderChart(this.data, this.chartOptions);
    },
};
</script>