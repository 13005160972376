<template>
    <div class="mt-5 px-xl-5">
        <h6 class="page-title pb-4 mt-3">

        </h6>
        <b-row class="mt-2">
            <b-col xl="12" md="12">
                <RecentOfficialCharts />
            </b-col>
        </b-row>
        <!-- <b-row class="mt-2">
            <b-col lg="5" md="4" class="mb-3">
                <b-card no-header no-body class="rounded-0 dash-card">
                    <h6 class="dash-card-title">
                        Requerimientos pendientes a agentes residentes
                    </h6>
                    <PendingRequestsAgent />
                </b-card>
            </b-col>
            <b-col lg="5" md="5" class="mb-3">
                <b-card no-header no-body class="rounded-0 dash-card">
                    <h6 class="dash-card-title">
                        Requerimientos pendientes de autoridades competentes
                    </h6>
                    <PendingRequestsAuthorities />
                </b-card>
            </b-col>
            <b-col lg="2" md="3">
                <b-card no-header no-body class="rounded-0 shadow-sm cs-pointer mini-card mb-1">
                    <b-row class="my-2">
                        <b-col>
                            <b-icon icon="plus" class="mini-card-plus"></b-icon>
                            <h6 class="mini-card-title">
                                Solicitud informaci&oacute;n
                            </h6>
                            <div class="icon-container">
                                <b-icon-clipboard-check font-scale="2"></b-icon-clipboard-check>
                            </div>
                        </b-col>
                    </b-row>
                </b-card>
                <b-card no-header no-body class="rounded-0 mt-1 shadow-sm cs-pointer mini-card mb-1">
                    <b-row class="my-2">
                        <b-col>
                            <b-icon icon="plus" class="mini-card-plus"></b-icon>
                            <h6 class="mini-card-title">
                                Notificaci&oacute;n nueva
                            </h6>
                            <div class="icon-container">
                                <b-icon-envelope-open font-scale="2"></b-icon-envelope-open>
                            </div>
                        </b-col>
                    </b-row>
                </b-card>
                <b-card no-header no-body class="rounded-0 mt-1 shadow-sm cs-pointer mini-card mb-1">
                    <b-row class="my-2">
                        <b-col>
                            <b-icon icon="plus" class="mini-card-plus"></b-icon>
                            <h6 class="mini-card-title">
                                Sanci&oacute;n nueva
                            </h6>
                            <div class="icon-container">
                                <b-icon-exclamation-square font-scale="2"></b-icon-exclamation-square>
                            </div>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col xl="4" md="4" class="mt-3">
                <b-card no-header no-body class="rounded-0 dash-card">
                    <h6 class="dash-card-title">Requerimientos urgentes</h6>
                    <RegistryRequests />
                </b-card>
            </b-col>
            <b-col xl="4" md="4" class="mt-3">
                <b-card no-header no-body class="dash-card rounded-0">
                    <b-tabs fill nav-class="dash-nav-tab">
                        <b-tab title="Pendientes" active title-item-class="dash-tab">
                            <PendingRequests />
                        </b-tab>
                        <b-tab title="Gestionadas" title-item-class="dash-tab">
                            <ManagedRequests />
                        </b-tab>
                    </b-tabs>
                </b-card>
            </b-col>
            <b-col xl="4" md="4" class="mt-3">
                <b-card no-header no-body class="rounded-0 card-input">
                    <h6 class="dash-card-title">Personas jur&iacute;dicas</h6>
                    <div class="dash-card-input d-flex">
                        <b-form-input v-model="entity" placeholder="Buscar por nombre entidad o RUC" class="rounded-0"
                            size="sm"></b-form-input>
                        <b-button class="ms-3 rounded-0" size="sm"><b-icon-search></b-icon-search></b-button>
                    </div>
                </b-card>
                <b-card no-header no-body class="rounded-0 mt-4 card-input">
                    <h6 class="dash-card-title">Agente residente</h6>
                    <div class="dash-card-input d-flex">
                        <b-form-input v-model="agent" size="sm" placeholder="Buscar por nombre de agente residente"
                            class="rounded-0"></b-form-input>
                        <b-button class="ms-3 rounded-0" size="sm"><b-icon-search></b-icon-search></b-button>
                    </div>
                </b-card>
            </b-col>
        </b-row> -->
    </div>
</template>

<script>
// import PendingRequestsAgent from "../dashboard-superintendencia/PendingRequestsAgent.vue";
// import PendingRequestsAuthorities from "../dashboard-superintendencia/PendingRequestsAuthorities.vue";
// import RegistryRequests from "../dashboard-superintendencia/RegistryRequests.vue";
// import PendingRequests from "../dashboard-superintendencia/PendingRequests.vue";
// import ManagedRequests from "../dashboard-superintendencia/ManagedRequests.vue";
import RecentOfficialCharts from "@/components/dashboard-official/RecentOfficialCharts.vue";
import { mapGetters } from "vuex";

export default {
    name: "official-dashboard",
    components: {
        // PendingRequestsAgent,
        // PendingRequestsAuthorities,
        // RegistryRequests,
        // PendingRequests,
        // ManagedRequests,
        RecentOfficialCharts
    },
    data() {
        return {
            entity: null,
            agent: null,
        };
    },
    computed: {
        ...mapGetters("application", [
            "allowOfficialDashboard",
            "allowOfficialDashboardStadistics",
        ]),
    },
};
</script>