<template>
    <div class="text-center">
        <b-dropdown variant="secondary" class="m-2" no-caret>
            <template #button-content>
                <div>
                    <b-icon-download v-if="!downloading" />
                    <b-icon
                        v-else
                        icon="arrow-clockwise"
                        animation="spin"
                        font-scale="1.5"
                    ></b-icon>
                </div>
            </template>
            <b-dropdown-item
                @click="downloadLawFirmReport"
                :disabled="downloading"
                >{{ $t("report.by-law-firm") }}</b-dropdown-item
            >
            <b-dropdown-item
                @click="downloadLawFirmDayReport"
                :disabled="downloading"
            >
                {{ $t("report.by-law-firm-day") }}
            </b-dropdown-item>
            <b-dropdown-item
                @click="downloadCountriesReport"
                :disabled="downloading"
                >{{ $t("report.by-country") }}</b-dropdown-item
            >
        </b-dropdown>
    </div>
</template>

<script>
import { RepositoryFactory } from "@/services/api";

export default {
    name: "legal-entities-by-law-firms-reports",
    data() {
        return {
            downloading: false,
        };
    },
    methods: {
        async downloadLawFirmReport() {
            this.downloading = true;
            try {
                let response =
                    await RepositoryFactory.stadistics.getLawFirmsReport();
                this.downloadFile(
                    response.data,
                    "Entidades jurídicas por bufete.csv"
                );
            } catch (error) {
                window.console.log(error);
            }
            this.downloading = false;
        },
        async downloadLawFirmDayReport() {
            this.downloading = true;
            try {
                let response =
                    await RepositoryFactory.stadistics.getLawFirmsDayReport();
                this.downloadFile(
                    response.data,
                    "Entidades jurídicas por bufete y día.csv"
                );
            } catch (error) {
                window.console.log(error);
            }
            this.downloading = false;
        },
        async downloadCountriesReport() {
            this.downloading = true;
            try {
                let response =
                    await RepositoryFactory.stadistics.getLegalEntitiesByCountryReport();
                this.downloadFile(
                    response.data,
                    "Entidades jurídicas por país.csv"
                );
            } catch (error) {
                window.console.log(error);
            }
            this.downloading = false;
        },
        downloadFile(data, filename) {
            const blob = new Blob([data], { type: "text/csv" });
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = filename;
            link.click();
            URL.revokeObjectURL(link.href);
        },
    },
};
</script>