<template>
    <div class="chart-container">
        <BarChartCustom :data="chartData" :scales="defaultScales" />
    </div>
</template>

<script>
import config from "@/config/charts-colors.js";

const colors = config.chartsColors;

import BarChartCustom from "./BarChartCustom.vue";

export default {
    name: "chart-wrapper",
    components: {
        BarChartCustom,
    },
    props: {
        data: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            defaultScales: {
                xAxes: [{
                    ticks: {
                        minRotation: 90,
                        padding: -32
                    },
                    gridLines: {
                        display: false, // hide separate line each bar
                        drawOnChartArea: false,
                        tickMarkLength: 40
                    }
                },
                {
                    display: false, // hide labels
                    offset: false,
                    labels: this.data.map(n => Object.keys(n.months)).flat(),
                    gridLines: {
                        display: false
                    },
                },
                {
                    offset: true,
                    labels: this.data.map(n => n.year).flat(),
                    gridLines: {
                        display: true
                    },
                    scaleLabel: {
                        display: false,
                        labelString: 'Date'
                    }
                }],
                yAxes: [{
                    scaleLabel: {
                        display: false,
                        labelString: 'value'
                    },
                    ticks: {
                        beginAtZero: true,
                        display: true
                    },
                    gridLines: {
                        display: false
                    },
                }]
            },
            chartData: {
                labels: [],
                datasets: [
                    {
                        data: [],
                        fill: true,
                        borderWidth: 4,
                        backgroundColor: colors.backgroundBarColors,
                    }
                ],
            },
            chartOption: {
                maintainAspectRatio: false,
                responsive: true,
                title: {
                    display: false,
                    text: ""
                },
                legend: {
                    display: false
                },
                scales: {
                    xAxes: [{
                        ticks: {
                            minRotation: 90,
                            padding: -32
                        },
                        gridLines: {
                            display: false, // hide separate line each bar
                            drawOnChartArea: false,
                            tickMarkLength: 40
                        }
                    },
                    {
                        display: false, // hide labels
                        offset: false,
                        labels: this.data.map(n => Object.keys(n.months)).flat(),
                        gridLines: {
                            display: false
                        },
                    },
                    {
                        offset: true,
                        labels: this.data.map(n => n.year).flat(),
                        gridLines: {
                            display: true
                        },
                        scaleLabel: {
                            display: false,
                            labelString: 'Date'
                        }
                    }],
                    yAxes: [{
                        scaleLabel: {
                            display: false,
                            labelString: 'value'
                        },
                        ticks: {
                            beginAtZero: true,
                            display: true
                        },
                        gridLines: {
                            display: false
                        },
                    }]
                },
            }
        };
    },
    created() {
        const months = this.data.map(n => Object.keys(n.months)).flat();
        const values = this.data.map(n => Object.values(n.months)).flat();
        this.chartData.labels = months;
        this.chartData.datasets[0].data = values;
    },
};
</script>

<style scoped>
.chart-container {
    margin: auto;
}
</style>