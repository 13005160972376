<template>
    <b-row v-if="loading">
        <b-col>
            <div class="text-center text-primary my-2">
                <b-spinner class="align-middle"></b-spinner>
            </div>
            <div class="text-center text-primary my-2">
                <strong>Cargando Suma de Cargas</strong>
            </div>
        </b-col>
    </b-row>
    <div v-else>
        <div class="box-menu">
            <b-icon icon="book" v-show="!details" @click="showModal" v-b-tooltip.hover
                title="Carga Actual de Registro Público" scale="1" />
            <b-icon icon="arrow-clockwise" @click="retrieveStadistics" v-b-tooltip.hover title="Actualizar" scale="1" />
        </div>

        <ResidentAgentChart title="Carga Actual" :labels="labels" :data="dataChart" :showPercent=true />

        <b-modal v-model="modal" content-class="rounded-0" dialog-class="modal-sm" header-class="border-0" centered
            hide-header hide-footer no-close-on-backdrop>
            <div class="container">
                <b-form-group class="mt-3">
                    <label class="form-title">
                        Carga Actual en Registro Público*</label>
                    <b-form-input class="rounded-0" type="number" v-model="amount" />
                </b-form-group>

                <div class="text-end">
                    <b-overlay :show="sending" rounded opacity="0.7" spinner-small spinner-variant="primary"
                        class="d-inline-block">
                        <b-button class="my-4 main-btn me-3" @click="saveModal" :disabled="sending">
                            Guardar
                        </b-button>
                    </b-overlay>
                    <b-button class="my-4 btn btn-danger" @click="closeModal">
                        <span>Cancelar</span>
                    </b-button>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
import { RepositoryFactory } from "@/services/api";

import ResidentAgentChart from "@/components/dashboard-superintendencia/charts/ResidentAgentChart.vue";

export default {
    name: "resident-agent-superintendence-summary-load-chart",
    components: {
        ResidentAgentChart,
    },
    data() {
        return {
            loading: true,
            dataChart: {
                percent: [],
                count: 0,
                total: 0,
                naturalPersonPercent: 0,
                legalPersonPercent: 0,
                legalPersonCount: 0,
                naturalPersonCount: 0
            },
            labels: ['Perosnas Jurídicas', 'Perosnas Jurídicas'],
            datalabels: [],
            details: false,
            modal: false,
            sending: false,
            amount: 0
        };
    },
    mounted() {
        this.retrieveStadistics();
    },
    methods: {
        async retrieveStadistics() {
            this.loading = true;
            let response;
            try {
                response = await RepositoryFactory.stadistics.getSuperintendenceSummaryLoad();
                this.dataChart.percent = response.data.percent;
                this.dataChart.count = response.data.count;
                this.dataChart.total = response.data.total;
                this.dataChart.naturalPersonPercent = 0;
                this.dataChart.legalPersonPercent = 0;
                this.dataChart.legalPersonCount = 0;
                this.dataChart.naturalPersonCount = 0;

            } catch {
                window.console.log("Error", response);
            }
            this.loading = false;
        },
        showDetails() {
            this.details = !this.details;
        },
        showModal() {
            this.modal = true;
        },
        closeModal() {
            this.modal = false;
        },
        async saveModal() {
            try {
                this.sending = true;
                var payload = {
                    amount: this.amount
                };

                await RepositoryFactory.stadistics.setCurrentLoad(
                    payload
                );

                this.closeModal();
                this.retrieveStadistics();
            } catch (error) {
                console.log(error);
            } finally {
                this.sending = false;
            }
        },
    },
};
</script>
<style>
.box-menu {
    display: flex;
    gap: 5px;
    flex-direction: row-reverse;
    cursor: pointer;
    z-index: -1;
}
</style>