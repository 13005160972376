<template>
    <div class="chart-container">
        <PieChart :data="chartData" />
    </div>
</template>

<script>
import config from "@/config/charts-colors.js";
const colors = config.chartsColors;

import PieChart from "./PieChart.vue";

export default {
    name: "chart-wrapper",
    components: {
        PieChart,
    },
    props: {
        labels: {
            type: Array,
            required: true,
        },
        values: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            chartData: {
                labels: [],
                datasets: [
                    {
                        data: [],
                        fill: true,
                        borderWidth: 4,
                        backgroundColor: colors.backgroundColors,
                        borderColor: colors.borderColors,
                    },
                ],
            },
        };
    },
    created() {
        this.chartData.labels = this.labels;
        this.chartData.datasets[0].data = this.values;
    },
};
</script>

<style scoped>
.chart-container {
    max-width: 250px;
    margin: auto;
}
</style>