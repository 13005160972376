<template>
    <b-row v-if="loading">
        <b-col>
            <div class="text-center text-primary my-2">
                <b-spinner class="align-middle"></b-spinner>
            </div>
            <div class="text-center text-primary my-2">
                <strong>Cargando Autoridades Competentes </strong>
            </div>
        </b-col>
    </b-row>
    <div v-else>
        <div class="box-menu">
            <b-icon icon="arrow-clockwise" @click="retrieveStadistics" v-b-tooltip.hover title="Actualizar" scale="1" />
        </div>
        <h5 class="card-title">Autoridades Competentes</h5>
        <div class="table">
            <b-table stacked="md" small hover select-mode="single" selectable show-empty
                empty-text="No hay datos que mostrar" :fields="fields" :items="data">
            </b-table>
        </div>
    </div>
</template>

<script>
import { RepositoryFactory } from "@/services/api";

export default {
    name: "competent-authority-chart",
    components: {
    },
    data() {
        return {
            loading: true,
            fields: [
                { key: "name", label: "" },
            ],
            data: null
        };
    },
    mounted() {
        this.retrieveStadistics();
    },
    methods: {
        async retrieveStadistics() {
            this.loading = true;
            let response;
            try {
                response = await RepositoryFactory.stadistics.getSuperintendenceCompetentAuthorityLoad();
                this.data = response.data
            } catch {
                window.console.log("Error", response);
            }
            this.loading = false;
        },
    },
};
</script>
<style>
.table {
    height: 30vh;
    overflow-y: scroll;
}
</style>