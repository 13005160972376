export default {
    //https://coolors.co/003f5c-2f4b7c-665191-a05195-d45087-f95d6a-ff7c43-ffa600
    chartsColors: {
        backgroundColors: [
            "#456B8F",
            "#C8BFE7",
            "rgba(102,81,145,0.7)",
            "rgba(160,81,149,0.7)",
            "rgba(212,80,135,0.7)",
            "rgba(249,93,106,0.7)",
            "rgba(255,124,67,0.7)",
        ],
        borderColors: [
            "rgba(0,63,92,1)",
            "rgba(47,75,124,1)",
            "rgba(102,81,145,1)",
            "rgba(160,81,149,1)",
            "rgba(212,80,135,1)",
            "rgba(249,93,106,1)",
            "rgba(255,124,67,1)",
        ],
        backgroundHalfDoughnutColors: [
            "#456B8F",
            "#C8BFE7"
        ],
        backgroundSegmentDoughnutColors: [
            "#456B8F",
            "#C8BFE7"
        ],
        backgroundBarColors: 
            "#456B8F",
        backgroundBarRiskColors: [ 
            "#B62E10",
            "#10B622"
        ]
    }
}