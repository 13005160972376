<template>
    <div>
        <div class="mt-1 mb-3 p-2 menu">
            <b-button @click="activeClick(0)" :pressed="activeButtom === 0" variant="outline-secondary">DASHBOARD
                GENERAL RUBF
            </b-button>
            <b-button @click="activeClick(1)" :pressed="activeButtom === 1" variant="outline-secondary">DASHBOARD
                PERSONA JURIDICA
            </b-button>
            <b-button @click="activeClick(2)" :pressed="activeButtom === 2" variant="outline-secondary">DASHBOARD
                A.C.
            </b-button>
            <b-button @click="activeClick(3)" :pressed="activeButtom === 3" variant="outline-secondary">DASHBOARD
                RIESGO
            </b-button>
            <!-- <b-button @click="activeClick(4)" :pressed="activeButtom === 4" pill variant="outline-secondary">Dashboard
                AR</b-button> -->
        </div>
        <div class="splash">
            <p>{{ title }}</p>
        </div>
        <div>
            <SuperintendenceCounters />
        </div>
        <div class="mb-3">
            <b-row align-h="center">
                <b-col md="4" v-if="activeButtom === 0">
                    <b-card no-body class="text-center p-2">
                        <b-row>
                            <b-col>
                                <ResidentAgentsSuperintendenceSummaryLoadChart />
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>

                <b-col md="3" v-if="activeButtom === 2">
                    <b-card no-body class="text-center p-2">
                        <b-row>
                            <b-col>
                                <SuperintendenceCompetentAuthorityChart />
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>
                <b-col md="3" v-if="activeButtom === 2">
                    <b-card no-body class="text-center p-2">
                        <b-row>
                            <b-col>
                                <SuperintendenceSummaryProceduresChart />
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>

                <b-col md="3" v-if="activeButtom === 2">
                    <b-card no-body class="text-center p-2">
                        <b-row>
                            <b-col>
                                <SuperintendenceFoliosProceduresChart />
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>

                <b-col md="3" v-if="activeButtom === 2">
                    <b-card no-body class="text-center p-2">
                        <b-row>
                            <b-col>
                                <SuperintendenceFoliosRequestsProcedureChart />
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>

                <b-col md="2" v-if="activeButtom === 3">
                    <b-card no-body class="text-center p-2">
                        <b-row>
                            <b-col>
                                <RiskHightSocietiesChart />
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>
                <b-col md="2" v-if="activeButtom === 3">
                    <b-card no-body class="text-center p-2">
                        <b-row>
                            <b-col>
                                <RiskPJProccecedCharts />
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>

                <b-col md="2" v-if="activeButtom === 3">
                    <b-card no-body class="text-center p-2">
                        <b-row>
                            <b-col>
                                <RiskARHightSocietiesChart />
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>

                <b-col md="2" v-if="activeButtom === 3">
                    <b-card no-body class="text-center p-2">
                        <b-row>
                            <b-col>
                                <RiskTotalCountriesWithMoreSocietiesChart />
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>

                <b-col md="4" v-if="activeButtom === 3">
                    <b-card no-body class="text-center p-2">
                        <b-row>
                            <b-col>
                                <RiskHightJurisdictionChart />
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>

                <!-- <b-col md="3" v-if="activeButtom === 4 || activeButtom === 0">
                    <b-card no-body class="text-center p-2">
                        <b-row>
                            <b-col>
                                <ResidentAgentsSuperintendenceNotifiedCharts />
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col> -->

                <!-- <b-col md="4" v-if="activeButtom === 1">
                    <b-card no-body class="text-center p-2">
                        <b-row>
                            <b-col>
                                <ResidentAgentPJSummaryLoadChart />
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col> -->

                <b-col md="6" v-if="activeButtom === 1">
                    <b-card no-body class="text-center p-2">
                        <b-row>
                            <b-col>
                                <RiskInPanamaChart />
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>

                <b-col md="6" v-if="activeButtom === 1">
                    <b-card no-body class="text-center p-2">
                        <b-row>
                            <b-col>
                                <RiskOutPanamaChart />
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>

                <b-col md="4" v-if="activeButtom === 4 || activeButtom === 0">
                    <b-card no-body class="text-center p-2">
                        <b-row>
                            <b-col>
                                <ResidentAgentsSuperintendenceRegisteredCharts />
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>

                <b-col md="4" v-if="activeButtom === 4 || activeButtom === 0">
                    <b-card no-body class="text-center p-2">
                        <b-row>
                            <b-col>
                                <ResidentAgentsSuperintendenceInProdCharts />
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>
            </b-row>
        </div>
        <div class="mt-3">
            <b-row align-h="center">
                <b-col md="4" v-if="activeButtom === 1">
                    <b-card no-body class="text-center p-2">
                        <b-row>
                            <b-col>
                                <ResidentAgentTopologyLoadChart />
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>

                <b-col md="4" v-if="activeButtom === 1">
                    <b-card no-body class="text-center p-2">
                        <b-row>
                            <b-col>
                                <PJClasificationLoadChart />
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>
                <b-col md="3" v-if="activeButtom === 2">
                    <b-card no-body class="text-center p-2">
                        <b-row>
                            <b-col>
                                <SuperintendenceCompletedProceduresChart />
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>
                <b-col md="4" v-show="activeButtom === 0">
                    <b-card no-body class="text-center p-2">
                        <b-row>
                            <b-col>
                                <ResidentAgentsSuperintendenceYearlyLoadChart />
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>
                <b-col md="4" v-show="activeButtom === 1">
                    <b-card no-body class="text-center p-2">
                        <b-row>
                            <b-col>
                                <ResidentAgentsSuperintendenceYearlyLoadChart />
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>
                <!-- <b-col md="3" v-if="activeButtom === 0">
                    <b-card no-body class="text-center p-2">
                        <b-row>
                            <b-col>
                                <ResidentAgentsSuperintendenceNotifiedSegmentCharts />
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col> -->
                <b-col md="4" v-if="activeButtom === 0">
                    <b-card no-body class="text-center p-2">
                        <b-row>
                            <b-col>
                                <ResidentAgentsSuperintendenceRegisteredSegmentCharts />
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>
                <b-col md="4" v-if="activeButtom === 0">
                    <b-card no-body class="text-center p-2">
                        <b-row>
                            <b-col>
                                <ResidentAgentsSuperintendenceInProdSegmentCharts />
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>
                <b-col md="3" v-if="activeButtom === 2">
                    <b-card no-body class="text-center p-2">
                        <b-row>
                            <b-col>
                                <SuperintendencePendingProceduresChart />
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>
                <b-col md="3" v-if="activeButtom === 2">
                    <b-card no-body class="text-center p-2">
                        <b-row>
                            <b-col>
                                <SuperintendenceCancelledProceduresChart />
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>
                <b-col md="3" v-if="activeButtom === 2">
                    <b-card no-body class="text-center p-2">
                        <b-row>
                            <b-col>
                                <SuperintendencePJRequestedProceduresChart />
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>


                <b-col md="6" v-if="activeButtom === 3">
                    <b-card no-body class="text-center p-2">
                        <b-row>
                            <b-col>
                                <RiskInOutPanamaChart />
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>
                <b-col md="6" v-if="activeButtom === 3">
                    <b-card no-body class="text-center p-2">
                        <b-row>
                            <b-col>
                                <RiskHightCountriesChart />
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>

            </b-row>
        </div>
        <div class="mt-3">
            <b-row align-h="center">
                <b-col md="6" v-if="activeButtom === 2">
                    <b-card no-body class="text-center p-2">
                        <b-row>
                            <b-col>
                                <SuperintendeceAttentionChart />
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>

                <b-col md="6" v-if="activeButtom === 2">
                    <b-card no-body class="text-center p-2">
                        <b-row>
                            <b-col>
                                <RiskHightSocietiesChart />
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>
            </b-row>
        </div>
        <div class="mt-3">
            <b-row align-h="center">
                <b-col md="12" v-show="activeButtom === 1 || activeButtom === 0">
                    <b-card no-body class="text-center p-2">
                        <b-row>
                            <b-col>
                                <ResidentAgentsSuperintendenceMonthlyLoadChart />
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>
                <b-col md="12" v-if="activeButtom === 2">
                    <b-card no-body class="text-center p-2">
                        <b-row>
                            <b-col>
                                <SuperintendenceRequestTimeChart />
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>
                <b-col md="12" v-if="activeButtom === 3">
                    <b-card no-body class="text-center p-2">
                        <b-row>
                            <b-col>
                                <RiskLevelsLoadChart />
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>
            </b-row>
        </div>
        <div class="mt-3">
            <b-row align-h="center">
                <b-col md="12" v-if="activeButtom === 3">
                    <b-card no-body class="text-center p-2">
                        <b-row>
                            <b-col>
                                <RiskHightActivitiesChart />
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>

import ResidentAgentsSuperintendenceInProdCharts from "@/components/dashboard-superintendencia/charts/ResidentAgentsSuperintendenceInProdCharts.vue";
import ResidentAgentsSuperintendenceRegisteredCharts from "@/components/dashboard-superintendencia/charts/ResidentAgentsSuperintendenceRegisteredCharts.vue";
// import ResidentAgentsSuperintendenceNotifiedCharts from "@/components/dashboard-superintendencia/charts/ResidentAgentsSuperintendenceNotifiedCharts.vue";
import ResidentAgentsSuperintendenceSummaryLoadChart from "@/components/dashboard-superintendencia/charts/ResidentAgentsSuperintendenceSummaryLoadChart.vue";
import ResidentAgentsSuperintendenceMonthlyLoadChart from "@/components/dashboard-superintendencia/charts/ResidentAgentsSuperintendenceMonthlyLoadChart.vue";
import ResidentAgentsSuperintendenceYearlyLoadChart from "@/components/dashboard-superintendencia/charts/ResidentAgentsSuperintendenceYearlyLoadChart.vue";
// import ResidentAgentPJSummaryLoadChart from "@/components/dashboard-superintendencia/charts/ResidentAgentPJSummaryLoadChart.vue";
import ResidentAgentTopologyLoadChart from "@/components/dashboard-superintendencia/charts/ResidentAgentTopologyLoadChart.vue";
import SuperintendenceSummaryProceduresChart from "@/components/dashboard-superintendencia/charts/SuperintendenceSummaryProceduresChart.vue";
import SuperintendencePJRequestedProceduresChart from "@/components/dashboard-superintendencia/charts/SuperintendencePJRequestedProceduresChart.vue";
import SuperintendenceCompletedProceduresChart from "@/components/dashboard-superintendencia/charts/SuperintendenceCompletedProceduresChart.vue";
import SuperintendencePendingProceduresChart from "@/components/dashboard-superintendencia/charts/SuperintendencePendingProceduresChart.vue";
import SuperintendenceCancelledProceduresChart from "@/components/dashboard-superintendencia/charts/SuperintendenceCancelledProceduresChart.vue";
import RiskHightSocietiesChart from "@/components/dashboard-superintendencia/charts/RiskHightSocietiesChart.vue";
import RiskARHightSocietiesChart from "@/components/dashboard-superintendencia/charts/RiskARHightSocietiesChart.vue";
import RiskTotalCountriesWithMoreSocietiesChart from "@/components/dashboard-superintendencia/charts/RiskTotalCountriesWithMoreSocietiesChart.vue";
import RiskLevelsLoadChart from "@/components/dashboard-superintendencia/charts/RiskLevelsLoadChart.vue";
import RiskInOutPanamaChart from "@/components/dashboard-superintendencia/charts/RiskInOutPanamaChart.vue";
import RiskHightCountriesChart from "@/components/dashboard-superintendencia/charts/RiskHightCountriesChart.vue";
import RiskHightActivitiesChart from "@/components/dashboard-superintendencia/charts/RiskHightActivitiesChart.vue";
import RiskHightJurisdictionChart from "@/components/dashboard-superintendencia/charts/RiskHightJurisdictionChart.vue";
import SuperintendenceFoliosProceduresChart from "@/components/dashboard-superintendencia/charts/SuperintendenceFoliosProceduresChart.vue";
import SuperintendenceFoliosRequestsProcedureChart from "@/components/dashboard-superintendencia/charts/SuperintendenceFoliosRequestsProcedureChart.vue";
import SuperintendeceAttentionChart from "@/components/dashboard-superintendencia/charts/SuperintendeceAttentionChart.vue";
import SuperintendenceRequestTimeChart from "@/components/dashboard-superintendencia/charts/SuperintendenceRequestTimeChart.vue";
import SuperintendenceCompetentAuthorityChart from "@/components/dashboard-superintendencia/charts/SuperintendenceCompetentAuthorityChart.vue";
import PJClasificationLoadChart from "@/components/dashboard-superintendencia/charts/PJClasificationLoadChart.vue";
import RiskPJProccecedCharts from "@/components/dashboard-superintendencia/charts/RiskPJProccecedCharts.vue";
//import ResidentAgentsSuperintendenceNotifiedSegmentCharts from "@/components/dashboard-superintendencia/charts/ResidentAgentsSuperintendenceNotifiedSegmentCharts.vue";
import SuperintendenceCounters from "@/components/dashboard-superintendencia/counters/SuperintendenceCounters.vue";
import ResidentAgentsSuperintendenceRegisteredSegmentCharts from "@/components/dashboard-superintendencia/charts/ResidentAgentsSuperintendenceRegisteredSegmentCharts.vue";
import ResidentAgentsSuperintendenceInProdSegmentCharts from "@/components/dashboard-superintendencia/charts/ResidentAgentsSuperintendenceInProdSegmentCharts.vue";
import RiskInPanamaChart from "@/components/dashboard-superintendencia/charts/RiskInPanamaChart.vue";
import RiskOutPanamaChart from "@/components/dashboard-superintendencia/charts/RiskOutPanamaChart.vue";

export default {
    name: "resident-agent-superintendence-charts",
    components: {
        ResidentAgentsSuperintendenceInProdCharts,
        ResidentAgentsSuperintendenceRegisteredCharts,
        // ResidentAgentsSuperintendenceNotifiedCharts,
        ResidentAgentsSuperintendenceSummaryLoadChart,
        ResidentAgentsSuperintendenceMonthlyLoadChart,
        ResidentAgentsSuperintendenceYearlyLoadChart,
        // ResidentAgentPJSummaryLoadChart,
        ResidentAgentTopologyLoadChart,
        SuperintendenceSummaryProceduresChart,
        SuperintendencePJRequestedProceduresChart,
        SuperintendenceCompletedProceduresChart,
        SuperintendencePendingProceduresChart,
        SuperintendenceCancelledProceduresChart,
        RiskHightSocietiesChart,
        RiskARHightSocietiesChart,
        RiskTotalCountriesWithMoreSocietiesChart,
        RiskLevelsLoadChart,
        RiskInOutPanamaChart,
        RiskHightCountriesChart,
        RiskHightActivitiesChart,
        RiskHightJurisdictionChart,
        SuperintendenceFoliosProceduresChart,
        SuperintendenceFoliosRequestsProcedureChart,
        SuperintendeceAttentionChart,
        SuperintendenceRequestTimeChart,
        SuperintendenceCompetentAuthorityChart,
        PJClasificationLoadChart,
        RiskPJProccecedCharts,
        SuperintendenceCounters,
        // ResidentAgentsSuperintendenceNotifiedSegmentCharts,
        ResidentAgentsSuperintendenceRegisteredSegmentCharts,
        ResidentAgentsSuperintendenceInProdSegmentCharts,
        RiskInPanamaChart,
        RiskOutPanamaChart
    },
    data() {
        return {
            activeButtom: 0,
            title: 'DASHBOARD GENERAL RUBF'
        }
    },
    methods: {
        activeClick(value) {
            this.activeButtom = value;

            switch (value) {
                case 0: this.title = 'DASHBOARD GENERAL RUBF'; break;
                case 1: this.title = 'DASHBOARD PERSONA JURIDICA'; break;
                case 2: this.title = 'DASHBOARD A.C.'; break;
                case 3: this.title = 'DASHBOARD RIESGO'; break;
            }
        }
    }

};
</script>

<style>
.menu {
    display: flex;
    gap: 5px;
    align-content: center;
    justify-content: center;
}

.menu b-button {
    background-color: #456B8F;
    border-color: #456B8F;
    border-radius: 0%;
}

.splash {
    background-color: #456B8F;
    text-align: center;
}

.splash p {
    color: #FFF;
    font-size: 18px;
    font-weight: bold;
    padding: 5px;
}
</style>