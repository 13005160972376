<template>
    <b-row v-if="loading">
        <b-col>
            <div class="text-center text-primary my-2">
                <b-spinner class="align-middle"></b-spinner>
            </div>
            <div class="text-center text-primary my-2">
                <strong>Cargando AR Registrados</strong>
            </div>
        </b-col>
    </b-row>
    <div v-else>
        <div class="box-menu">
            <b-icon icon="arrow-clockwise" @click="retrieveStadistics" v-b-tooltip.hover title="Actualizar" scale="1" />
        </div>

        <BarChartCustomWrapper :typeChart="'Segmentation'" :label="label" title="Segmentación AR Registrados"
            :data="dataSegmentation" />
    </div>
</template>

<script>
import { RepositoryFactory } from "@/services/api";
import BarChartCustomWrapper from "@/components/charts/BarChartCustomWrapper.vue";
import { mapActions, mapGetters } from "vuex";

function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

export default {
    name: "resident-agent-superintendence-registered-segment-charts",
    components: {
        BarChartCustomWrapper
    },
    data() {
        return {
            loading: true,
            dataChart: {
                percent: [],
                count: 0,
                total: 0,
                naturalPersonPercent: 0,
                legalPersonPercent: 0,
                legalPersonCount: 0,
                naturalPersonCount: 0
            },
            dataSegmentation: [],
            label: 'Agentes Residentes',
            labels: ['Agentes Residentes', 'Agentes Residentes']
        };
    },
    mounted() {
        this.retrieveStadistics();
    },
    methods: {
        ...mapActions("StadisticStore", ["setRegisteredChart"]),
        ...mapGetters("StadisticStore", ["getRegisteredChart"]),
        async retrieveStadistics() {
            this.loading = true;
            const store = this.getRegisteredChart()
            if (store === null || store === undefined) {
                try {
                    let response = await RepositoryFactory.stadistics.getSuperintendenceResidentAgentRegistered();
                    this.setDataChart(response.data);
                    this.setRegisteredChart(response.data)
                } catch {
                    window.console.log("Error");
                }
            } else {
                this.setDataChart(store);
            }
            await sleep(1500);
            this.loading = false;
        },
        setDataChart(data) {
            this.dataSegmentation = [
                {
                    key: 'Firmas de Abogados', value: data.legalPersonCount,
                },
                {
                    key: 'Persona Natural', value: data.naturalPersonCount,
                }
            ]
        },
    },

};
</script>
<style>
.box-menu {
    display: flex;
    gap: 5px;
    flex-direction: row-reverse;
    cursor: pointer;
    z-index: -1;
}
</style>