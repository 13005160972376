<template>
    <div>
        <h5 class="card-title">{{ title }}</h5>
        <HalfDoughnutChartWrapper :labels="chartlabels" :values="values" :counter="counter" :total="total"
            :showPercent="chartShowPercent" />
    </div>
</template>

<script>
import HalfDoughnutChartWrapper from "@/components/charts/HalfDoughnutChartWrapper.vue";

export default {
    name: "resident-agents-charts",
    components: {
        HalfDoughnutChartWrapper,
    },
    props: {
        title: {
            type: String,
            required: true
        },
        labels: {
            type: Array,
            required: false
        },
        showPercent: {
            type: Boolean,
            required: true
        },
        data: {
            required: true
        }
    },
    data() {
        return {
            chartlabels: [],
            values: [],
            chartTitle: '',
            counter: 0,
            total: 0,
            chartShowPercent: true,
        };
    },
    created() {
        this.chartTitle = this.title;
        this.chartShowPercent = this.showPercent;
        this.values = [this.data.percent, 100 - this.data.percent];
        this.counter = this.data.count;
        this.total = this.data.total;
        const datalabels = !this.showPercent ? ['', ''] : this.labels.map((l, i) => `${l} ${this.values[i].toFixed(2)}%`);
        this.chartlabels = datalabels;
    },
};
</script>