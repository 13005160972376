<template>
    <b-row v-if="loading">
        <b-col>
            <div class="text-center text-primary my-2">
                <b-spinner class="align-middle"></b-spinner>
            </div>
            <div class="text-center text-primary my-2">
                <strong>Cargando Suma de Cargas de Personas Jurídicas </strong>
            </div>
        </b-col>
    </b-row>
    <div v-else>
        <h5 class="card-title">Personas Jurídicas</h5>
        <h1>{{ dataChart.count }}</h1>
        <!-- <ResidentAgentChart title="Personas Jurídicas" :labels="['Activas', 'Inactivas']" :data="dataChart"
            :showPercent=true /> -->
    </div>
</template>

<script>
import { RepositoryFactory } from "@/services/api";

// import ResidentAgentChart from "@/components/dashboard-resident-agent/charts/ResidentAgentChart.vue";

function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

export default {
    name: "resident-agent-pj-summary-chart",
    components: {
        // ResidentAgentChart,
    },
    data() {
        return {
            loading: true,
            dataChart: {
                percent: [],
                count: 0,
                total: 0,
            },
        };
    },
    mounted() {
        this.retrieveStadistics();
    },
    methods: {
        async retrieveStadistics() {
            this.loading = true;
            let response;
            try {
                response = await RepositoryFactory.stadistics.getResidetAgentPJSummaryLoad();
                console.log(response.data)
                this.dataChart.percent = response.data.percent;
                this.dataChart.count = response.data.count;
                this.dataChart.total = response.data.total;
            } catch {
                window.console.log("Error", response);
            }
            await sleep(1500);
            this.loading = false;
        },
    },
};
</script>