<template>
    <div class="mb-5">
        <b-row v-if="loading">
            <b-col>
                <div class="text-center text-primary my-2">
                    <b-spinner class="align-middle"></b-spinner>
                </div>
                <div class="text-center text-primary my-2">
                    <strong>Cargando datos de las firmas</strong>
                </div>
            </b-col>
        </b-row>
        <b-row align-h="center" v-else>
            <b-col md="4">
                <b-card no-body class="text-center p-2">
                    <h5 class="card-title">Agentes Residentes</h5>
                    <b-row>
                        <b-col>
                            <LegalEntitiesByLawFirmChart :data="stadistics" />
                            <LegalEntitiesByLawFirmReports />
                        </b-col>
                    </b-row>
                    <b-row class="mt-3">
                        <b-col>
                            <FinalBeneficiariesByLawFirmChart :data="stadistics" />
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
            <b-col md="4">
                <b-card no-body class="text-center p-2">
                    <h5 class="card-title">Sociedades Civiles</h5>
                    <b-row>
                        <b-col>
                            <LegalEntitiesByLawFirmChart :data="legalPersons" />
                        </b-col>
                    </b-row>
                    <b-row class="mt-3">
                        <b-col>
                            <FinalBeneficiariesByLawFirmChart :data="legalPersons" />
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
            <b-col md="4">
                <b-card no-body class="text-center p-2">
                    <h5 class="card-title">Abogados (Personas Naturales)</h5>
                    <b-row>
                        <b-col>
                            <LegalEntitiesByLawFirmChart :data="naturalPersons" />
                        </b-col>
                    </b-row>
                    <b-row class="mt-3">
                        <b-col>
                            <FinalBeneficiariesByLawFirmChart :data="naturalPersons" />
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import { RepositoryFactory } from "@/services/api";
import LegalEntitiesByLawFirmChart from "./LegalEntitiesByLawFirmChart.vue";
import FinalBeneficiariesByLawFirmChart from "./FinalBeneficiariesByLawFirmChart.vue";
import LegalEntitiesByLawFirmReports from "../reports/LegalEntitiesByLawFirmsReports.vue";

function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

export default {
    name: "superintendence-charts",
    components: {
        LegalEntitiesByLawFirmChart,
        FinalBeneficiariesByLawFirmChart,
        LegalEntitiesByLawFirmReports,
    },
    data() {
        return {
            loading: true,
            stadistics: [],
        };
    },
    mounted() {
        this.retrieveStadistics();
    },
    computed: {
        legalPersons() {
            return this.stadistics.filter((s) => s.type === 0);
        },
        naturalPersons() {
            return this.stadistics.filter((s) => s.type === 1);
        },
    },
    methods: {
        async retrieveStadistics() {
            this.loading = true;
            try {
                let response =
                    await RepositoryFactory.stadistics.getLegalEntitiesByLawFirm();
                this.stadistics = response.data;
            } catch {
                window.console.log("Error");
            }
            await sleep(1500);
            this.loading = false;
        },
    },
};
</script>