<template>
    <div class="mb-3">
        <b-row v-if="loading">
            <b-col>
                <div class="text-center text-primary my-2">
                    <b-spinner class="align-middle"></b-spinner>
                </div>
                <div class="text-center text-primary my-2">
                    <strong>Cargando contadores</strong>
                </div>
            </b-col>
        </b-row>
        <b-row v-else>
            <b-col sm="2">
                <Counter
                    label="Personas Jurídicas"
                    :value="counters.legalEntities"
                />
            </b-col>
            <b-col sm="2">
                <Counter
                    label="Beneficiarios finales"
                    :value="counters.finalBeneficiaries"
                />
            </b-col>
            <b-col sm="2">
                <Counter label="Agentes Residentes"
                    :value="counters.lawFirms"
                    :subValue="counters.arLawFirms"
                />
            </b-col>
            <b-col sm="3">
                <Counter
                    label="Firmas (Sociedades Civiles)"
                    :value="counters.legalPersons"
                    :subValue="counters.arLegalPersons"
                />
            </b-col>
            <b-col sm="3">
                <Counter
                    label="Abogados(Personas Naturales)"
                    :value="counters.naturalPersons"
                    :subValue="counters.arNaturalPersons"
                />
            </b-col>
<!--            <b-col sm="2">
                <Counter label="Roles" :value="counters.roles" />
            </b-col>-->
        </b-row>
    </div>
</template>

<script>
import { RepositoryFactory } from "@/services/api";
import Counter from "./Counter.vue";

export default {
    name: "superintendence-counters",
    components: {
        Counter,
    },
    data() {
        return {
            loading: false,
            counters: {},
        };
    },
    mounted() {
        this.retrieveCounters();
    },
    methods: {
        async retrieveCounters() {
            this.loading = true;
            try {
                let response =
                    await RepositoryFactory.stadistics.getSuperintendenceCounters();
                this.counters = response.data;
            } catch {
                this.counters = {
                    legalEntities: "ERROR",
                    finalBeneficiaries: "ERROR",
                    lawFirms: "ERROR",
                    roles: "ERROR",
                };
            }
            this.loading = false;
        },
    },
};
</script>