<template>
    <b-row v-if="loading">
        <b-col>
            <div class="text-center text-primary my-2">
                <b-spinner class="align-middle"></b-spinner>
            </div>
            <div class="text-center text-primary my-2">
                <strong>Cargando Solicitudes Mayor o Menor 24 Hrs </strong>
            </div>
        </b-col>
    </b-row>
    <div v-else>
        <div class="box-menu">
            <b-icon icon="arrow-clockwise" @click="retrieveStadistics" v-b-tooltip.hover title="Actualizar" scale="1" />
        </div>
        <h5 class="card-title">Atenciones de Solicitudes Mayor o Menor 24 Hrs</h5>

        <div class="legend-conatiner">
            <div class="label">
                <span class="law-dot"></span>
                <p>+24 Hrs</p>
            </div>
            <div class="label">
                <span class="natural-dot"></span>
                <p>-24 Hrs</p>
            </div>
        </div>

        <HalfDoughnutChartWrapper :labels="chartlabels" :values="values" :counter="counter" :total="total"
            :showPercent=true />

    </div>
</template>

<script>
import HalfDoughnutChartWrapper from "@/components/charts/HalfDoughnutChartWrapper.vue";
import { RepositoryFactory } from "@/services/api";

export default {
    name: "superintendence-attention-charts",
    components: {
        HalfDoughnutChartWrapper,
    },
    data() {
        return {
            loading: true,
            chartlabels: [],
            values: [],
            chartTitle: '',
            counter: 0,
            total: 0,
            chartShowPercent: true,
        };
    },

    mounted() {
        this.retrieveStadistics();
    },
    methods: {
        async retrieveStadistics() {
            this.loading = true;

            try {
                let response = await RepositoryFactory.stadistics.getSuperintendenceAttentionLoad();

                console.log()

                this.values = [response.data.minor, response.data.greater];
                this.counter = response.data.minor;
                this.total = response.data.greater + response.data.minor;
                this.chartlabels = [`-24Hrs ${response.data.minor} (${Math.trunc(response.data.minor * 100 / this.total)}%)`, `+24Hrs ${response.data.greater} (${Math.trunc(response.data.greater * 100 / this.total)}%)`];

            } catch {
                window.console.log("Error");
            }
            this.loading = false;
        },
    }
}
</script>
<style scoped>
.chart-container {
    max-width: 250px;
    margin: auto;
}

.legend-conatiner {
    display: flex;
    gap: 20px;

}

.law-dot {
    height: 10px;
    width: 10px;
    background-color: #A05195;
    border-radius: 50%;
    display: inline-block;
}

.natural-dot {
    height: 10px;
    width: 10px;
    background-color: #303680;
    border-radius: 50%;
    display: inline-block;
}

p {
    font-size: 10px;
}

.label {
    display: flex;
    gap: 5px;
}
</style>