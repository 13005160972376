<template>
    <b-row v-if="loading">
        <b-col>
            <div class="text-center text-primary my-2">
                <b-spinner class="align-middle"></b-spinner>
            </div>
            <div class="text-center text-primary my-2">
                <strong>Cargando Niveles de Riesgo fuera de Panamá</strong>
            </div>
        </b-col>
    </b-row>
    <div v-else>
        <div class="box-menu">
            <b-icon icon="arrow-clockwise" @click="retrieveStadistics(true)" v-b-tooltip.hover title="Actualizar"
                scale="1" />
        </div>
        <ResidentAgentChart title="Off/Shore (Fuera de Panamá)" :labels="['Fuera', 'Fuera']" :data="dataChart"
            :showPercent=true />
    </div>
</template>

<script>
import { RepositoryFactory } from "@/services/api";
import { mapActions, mapGetters } from "vuex";
import ResidentAgentChart from "@/components/dashboard-superintendencia/charts/ResidentAgentChart.vue";

export default {
    name: "risk-on-off-panama-charts",
    components: {
        ResidentAgentChart,
    },
    data() {
        return {
            loading: true,
            dataChart: null,
            label: 'Personas Juridicas'
        };
    },
    mounted() {
        this.retrieveStadistics(false);
    },
    methods: {
        ...mapActions("StadisticStore", ["setPJOutPanamaChart"]),
        ...mapGetters("StadisticStore", ["getPJOutPanamaChart"]),
        async retrieveStadistics(isRefrech) {
            this.loading = true;
            const store = isRefrech ? null : this.getPJOutPanamaChart()
            if (store === null || store === undefined) {
                try {
                    let response = await RepositoryFactory.stadistics.getPJOutPanamaLoad();
                    this.setData(response.data)
                } catch {
                    window.console.log("Error");
                }
            }
            else {
                this.setData(store)
            }
            this.loading = false;
        },
        setData(data) {
            this.dataChart = {
                count: data.count,
                total: data.total,
                percent: data.percent
            }
        }
    },
};
</script>