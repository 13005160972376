<template>
    <div class="chart-container">
        <h5 class="card-title">{{ title }}</h5>
        <BarChartCustom :data="chartData" :scales="customScales" />
    </div>
</template>

<script>
import config from "@/config/charts-colors.js";
const colors = config.chartsColors;

import BarChartCustom from "./BarChartCustom.vue";

export default {
    name: "chart-bar-activities-wrapper",
    components: {
        BarChartCustom,
    },
    props: {
        title: {
            type: String,
            required: false
        },
        data: {
            type: Object,
            required: true,
        },
        label: {
            type: String,
            required: false,
        }
    },
    data() {
        return {
            chartData: {
                labels: [],
                datasets: [
                    {
                        data: [],
                        fill: true,
                        borderWidth: 1,
                        backgroundColor: colors.backgroundBarColors,
                        label: this.label
                    }
                ],
            },
            chartOption: {
                maintainAspectRatio: false,
                responsive: true,
                title: {
                    display: false,
                    text: ""
                },
                legend: {
                    display: false
                },
            },
            customScales: {
                xAxes: [{
                    ticks: {
                        callback: function (value) {
                            return `${value.substr(0, 7)}...`
                        }
                    },
                }],
                yAxes: [{
                    scaleLabel: {
                        display: false,
                        labelString: 'value'
                    },
                    ticks: {
                        beginAtZero: true,
                        callback: function (value) {
                            return value; // Mostrar el valor de la barra
                        }
                    },
                    gridLines: {
                        display: false
                    },
                }]
            },
            plugins: {
                datalabels: {
                    anchor: 'end',
                    align: 'end',
                    formatter: (val) => (+ val)
                }
            }

        };
    },
    created() {
        const data = this.data.map(item =>
            item.value
        );
        const total = data.reduce((sum, val) => sum + val, 0);

        const collection = this.data.map(item => `${item.key} (${Math.trunc(item.value * 100 / total)}%)`);

        this.chartData.labels = collection;
        this.chartData.datasets[0].data = data;
    },
};
</script>

<style scoped>
.chart-container {
    margin: auto;
}
</style>