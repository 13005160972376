<template>
    <b-row v-if="loading">
        <b-col>
            <div class="text-center text-primary my-2">
                <b-spinner class="align-middle"></b-spinner>
            </div>
            <div class="text-center text-primary my-2">
                <strong>Cargando Solicitudes y Folios AC </strong>
            </div>
        </b-col>
    </b-row>
    <div v-else>
        <div class="box-menu">
            <b-icon icon="arrow-clockwise" @click="retrieveStadistics" v-b-tooltip.hover title="Actualizar" scale="1" />
        </div>
        <h5 class="card-title">Solicitudes y Folios AC</h5>
        <div class="table">
            <b-table stacked="md" small hover select-mode="single" selectable show-empty
                empty-text="No hay datos que mostrar" :fields="fields" :items="data">
            </b-table>
        </div>
    </div>
</template>

<script>
import { RepositoryFactory } from "@/services/api";

function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

export default {
    name: "risk-countries-with-more-societies-chart",
    components: {
    },
    data() {
        return {
            loading: true,
            fields: [
                { key: "ac", label: "AC" },
                { key: "requests", label: "Solicitudes" },
                { key: "folios", label: "Folios" },
            ],
            data: null
        };
    },
    mounted() {
        this.retrieveStadistics();
    },
    methods: {
        async retrieveStadistics() {
            this.loading = true;
            let response;
            try {
                response = await RepositoryFactory.stadistics.getSuperintendenceFoliosRequestsProceduresLoad();
                this.data = response.data

                this.data.push(
                    {
                        ac: 'TOTAL',
                        requests: this.data.reduce((n, { requests }) => n + requests, 0),
                        folios: this.data.reduce((n, { folios }) => n + folios, 0),
                    }
                )
            } catch {
                window.console.log("Error", response);
            }
            await sleep(1500);
            this.loading = false;
        },
    },
};
</script>
<style>
.table {
    /* height: 30vh; */
    max-height: 230px;
    overflow-y: scroll;
}
</style>