<template>
    <b-row v-if="loading">
        <b-col>
            <div class="text-center text-primary my-2">
                <b-spinner class="align-middle"></b-spinner>
            </div>
            <div class="text-center text-primary my-2">
                <strong>Cargando Detalles Solicitudes </strong>
            </div>
        </b-col>
    </b-row>
    <div v-else>
        <h5 class="card-title">Detalles de Solicitudes</h5>
        <div class="table">
            <b-tabs fill nav-class="dash-nav-tab" v-model="tabIndex">
                <b-tab title="Pendientes" title-item-class="dash-tab" @click="changeTab(0)">
                </b-tab>
                <b-tab title="Completados" title-item-class="dash-tab" @click="changeTab(1)">
                </b-tab>
                <b-tab title="Cancelados" title-item-class="dash-tab" @click="changeTab(2)">
                </b-tab>
            </b-tabs>
            <b-table stacked="md" small hover select-mode="single" selectable show-empty
                empty-text="No hay datos que mostrar" :fields="fields" :items="filters">
            </b-table>
        </div>
    </div>
</template>

<script>
import { RepositoryFactory } from "@/services/api";


function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

export default {
    name: "procedures-details-chart",
    components: {
    },
    data() {
        return {
            loading: true,
            fields: [
                { key: "name", label: "Nombre" },
                { key: "date", label: "Fecha" },
            ],
            data: null,
            filters: null
        };
    },
    mounted() {
        this.retrieveStadistics();
    },
    methods: {
        async retrieveStadistics() {
            this.loading = true;
            let response;
            try {
                response = await RepositoryFactory.stadistics.getSuperintendenceProcedureDetailsLoad();
                this.data = response.data
                this.changeTab(0)
            } catch {
                window.console.log("Error", response);
            }
            await sleep(1500);
            this.loading = false;
        },
        changeTab(index) {
            if (index === 0) {
                this.filters = this.data.filter(n => n.status === 'PendingFirstValidation' ||
                    n.status === 'PendingSecondValidation' ||
                    n.status === 'PendingRead'
                );
            }

            if (index === 1) {
                this.filters = this.data.filter(n => n.status === 'Closed'
                );
            }

            if (index === 2) {
                this.filters = this.data.filter(n => n.status === 'Canceled'
                );
            }
        },
    },
};
</script>
<style>
.table {
    height: 30vh;
    overflow-y: scroll;
}
</style>