<template>
    <b-row v-if="loading">
        <b-col>
            <div class="text-center text-primary my-2">
                <b-spinner class="align-middle"></b-spinner>
            </div>
            <div class="text-center text-primary my-2">
                <strong>Cargando total de trámites completados </strong>
            </div>
        </b-col>
    </b-row>
    <div v-else>
        <OfficialChart title="Total de Trámites Completados" :labels="['Completados', 'Sin Completar']"
            :data="dataChart" :showPercent=true />
    </div>
</template>

<script>
import { RepositoryFactory } from "@/services/api";

import OfficialChart from "@/components/dashboard-official/charts/OfficialChart.vue";

function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

export default {
    name: "official-completed-procedures-chart",
    components: {
        OfficialChart,
    },
    data() {
        return {
            loading: true,
            dataChart: {
                percent: [],
                count: 0,
                total: 0,
            },
        };
    },
    mounted() {
        this.retrieveStadistics();
    },
    methods: {
        async retrieveStadistics() {
            this.loading = true;
            let response;
            try {
                response = await RepositoryFactory.stadistics.getOfficialCompletedProcedures();
                console.log(response.data)
                this.dataChart.percent = response.data.percent;
                this.dataChart.count = response.data.count;
                this.dataChart.total = response.data.total;
            } catch {
                window.console.log("Error", response);
            }
            await sleep(1500);
            this.loading = false;
        },
    },
};
</script>