<template>
    <div class="mt-5 px-xl-5">
        <div v-if="allowDashboard">
            <div class="mb-3">
                <!-- <SuperintendenceCounters /> -->
                <ResidentAgentsSuperintendenceCharts />
            </div>
            <SuperintendenceCharts />
        </div>

        <b-card v-else class="shadow-sm border-0 rounded-0 p-2" bg-variant="light" no-header>
            <b-card-text class="text-center">
                <h5>No hay datos que mostrar</h5>
            </b-card-text>
        </b-card>
    </div>
</template>

<script>
// import SuperintendenceCounters from "./counters/SuperintendenceCounters.vue";
import SuperintendenceCharts from "./charts/SuperintendenceCharts.vue";
import ResidentAgentsSuperintendenceCharts from "./charts/ResidentAgentsSuperintendenceCharts";
import { mapGetters } from "vuex";

export default {
    name: "superintendencia-dash",
    components: {
        // SuperintendenceCounters,
        SuperintendenceCharts,
        ResidentAgentsSuperintendenceCharts
    },
    data() {
        return {
            entity: null,
            agent: null,
        };
    },
    computed: {
        ...mapGetters("application", ["allowSuperintendenceDashboard"]),
        allowDashboard() {
            return this.allowSuperintendenceDashboard;
        },
    },
};
</script>

<style>
.dash-card {
    border-bottom: 3px solid #597fa4;
    box-shadow: 4px 1px 5px #88888873;
    border-right: unset;
    border-top: unset;
    border-left: unset;
}

.dash-card-title {
    color: #406892;
    background-color: rgb(212 221 229);
    padding: 0.5rem 0 0.5rem 1rem;
    border-bottom: 1px solid #597fa4;
    margin-bottom: 0;
}

/*table*/
.dash-table {
    margin-bottom: 0;
}

.dash-table tbody tr {
    border: 1px solid #03a9f447;
    color: #746d6dfc;
    font-weight: 500;
    font-size: 14px;
}

.dash-table tbody>tr>td {
    padding-left: 1rem;
}

.dash-table thead {
    display: none;
}

.mini-card {
    background-color: #597fa4;
    border-bottom: 3px solid #ffffff;
}

.mini-card-title {
    color: #ffffff;
    border-bottom: 1.5px solid #ffffff;
    padding: 0 0 0.35rem 0.5rem;
    font-weight: 400;
}

.mini-card-plus {
    color: #ffffff;
    font-size: 26px;
    float: right;
    background-color: rgb(68 96 123);
    margin-top: -8px;
}

.icon-container {
    text-align: center;
    color: #ffffff;
}

.dash-tab .nav-link.active {
    background-color: rgb(212 221 229);
    border-radius: 0;
    color: #406892;
    font-weight: 500;
    border-bottom-color: rgb(212 221 229);
    margin-bottom: 0;
}

.dash-tab .nav-link {
    padding: 0.3rem;
    color: #746d6dfc;
    font-weight: 500;
}

.dash-tab .nav-link:hover {
    border-radius: 0;
}

.dash-nav-tab {
    border-bottom: 0.2px solid #597fa4;
}

.card-input {
    background-color: rgb(212 221 229);
    border-bottom: 3px solid #597fa4;
    box-shadow: 4px 1px 5px #88888873;
}

.card-input .dash-card-title {
    border-bottom: 2.3px solid #597fa4;
}

.dash-card-input {
    margin: 25px;
}

.dash-card-input button {
    background-color: #597fa4;
}

.report-card {
    background-color: #597fa4;
    color: #ffffff;
    border-bottom: 3px solid #cfcfcf !important;
}

.apexcharts-menu-icon {
    display: none !important;
}

@media (max-width: 768px) {
    .dash-table>tbody>tr>[data-label]>div {
        width: unset !important;
    }

    #authorities>tbody>tr>td[aria-colindex="2"] {
        text-align: center !important;
    }
}

@media (min-width: 768px) and (max-width: 1000px) {
    .mini-card-title {
        font-size: 14px;
    }

    .dash-card-input {
        margin: 15px;
    }
}
</style>